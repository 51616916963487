export default [
    {
        "name": "Eastern Standard Time",
        "GMT": "-5",
        "code": "EST"
    },
    {
        "name": "Eastern Daylight Time",
        "GMT": "-4",
        "code": "EST"
    },
    {
        "name": "Central Standard Time",
        "GMT": "-6",
        "code": "CT"
    },
    {
        "name": "Central Daylight Time",
        "GMT": "-5",
        "code": "CT"
    },
    {
        "name": "Mountain Standard Time",
        "GMT": "-7",
        "code": "MT"
    },
    {
        "name": "Mountain Daylight Time",
        "GMT": "-6",
        "code": "MT"
    },
    {
        "name": "Pacific Standard Time",
        "GMT": "-8",
        "code": "PT"
    },
    {
        "name": "Pacific Daylight Time",
        "GMT": "-7",
        "code": "PT"
    },
    {
        "name": "Alaska Standard Time",
        "GMT": "-9",
        "code": "AKST"
    },
    {
        "name": "Alaska Daylight Time",
        "GMT": "-8",
        "code": "AKST"
    }
    ,
    {
        "name": "Atlantic Standard Time",
        "GMT": "-4",
        "code": "AST"
    },
    {
        "name": "Atlantic Daylight Time",
        "GMT": "-3",
        "code": "AST"
    },
    {
        "name": "Chamorro Standard Time",
        "GMT": "+10",
        "code": "CHST"
    },
    {
        "name": "Wake Island Time",
        "GMT": "+12",
        "code": "WAKT"
    }
]
