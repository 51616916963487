import React from "react";

const LocationIcon = ({ fillColor, className, strokeColor }) => {
    const fill = (fillColor) ? fillColor : "none";
    const stroke = (strokeColor) ? strokeColor : "currentColor";
    let classes = `w-4 h-4`;
    if (className && className.indexOf('w-') >= 0) {
        classes = ``;
    }
    classes += ` ${className}`;
    return (

        <svg xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke={stroke}
            className={classes}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
        </svg>
    );
}

export default LocationIcon;
