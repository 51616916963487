import React, { useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import CommunityAccordion from "../components/community/CommunityAccordion";
import CommunityAccordionSkeleton from "../components/skeletons/CommunityAccordionSkeleton";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import JobListingCommunityAccordion from "../components/joblisting/JobListingCommunityAccordion";
// import { getCommunities, getAnonymousCommunityBudges, getCommunityBudges } from "../core/events";
import { getEvents, searchEvents } from "../core/events";
import { isAuthorised } from "../utils/auth";
import EditIcon from "../components/icons/Edit";
import MagnifyingGlassIcon from "../components/icons/MagnifyingGlass";
import { searchJobs } from '../core/job_listing/index';
import Loader from 'react-loaders';
import { Utils } from "../utils";
import EventsIcon from "../components/icons/EventsIcon";
import ClockIcon from "../components/icons/Clock";
import LocationIcon from "../components/icons/Location";
import GlobeIcon from "../components/icons/Globe";
import LoginAlertModal from "../components/auth/LoginAlertModal";
import { useNavigate } from "react-router-dom";
import EventListItem from "../components/events/EventListItem";



const Events = () => {
    const navigate = useNavigate();
    const [ads, setAds] = useState([]);
    const [events, setEvents] = useState([]);
    const [communityBudges, setCommunityBudges] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isLoginAlertModalOpen, setIsLoginAlertModalOpen] = useState(false);
    const toggleLoginAlertModalModal = () => {
        setIsLoginAlertModalOpen(!isLoginAlertModalOpen);
    };

    const isOrg = isAuthorised(["organisation"]);

    const fetchEvents = async () => {
        setIsLoading(true);

        // let communityBudges = {};
        // if (loggedInUser !== null) {
        //     communityBudges = await getCommunityBudges();
        //     setCommunityBudges(communityBudges);
        // } else {
        //     communityBudges = await getAnonymousCommunityBudges();
        //     setCommunityBudges(communityBudges);
        // }

        const data = await getEvents();
        const eventsData = data?.events ?? [];
        const adsData = data?.ads ?? [];
        setEvents(eventsData);
        setAds(adsData);
        setIsLoading(false);
    }

    const limit = -1;
    const [jobsList, setJobsList] = useState([]);
    const [errors, setErrors] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLocationTerm, setSearchLocationTerm] = useState('');

    const [isProgramLaunch, setIsProgramLaunch] = useState('false');
    const [isSocial, setIsSocial] = useState('false');
    const [isTraining, setIsTraining] = useState('false');
    const [isWebinar, setIsWebinar] = useState('false');
    const [isOther, setIsOther] = useState('false');
    const [isOnsite, setIsOnSite] = useState('false');
    const [isVirtue, setIsVirtue] = useState('false');

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitSearchForm();
        }
    };
    const handleLocationChange = (event) => {
        setSearchLocationTerm(event.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        submitSearchForm();
    }
    const submitSearchForm = async () => {
        setErrors([]);
        setIsLoading(true);
        const inputs = {
            searchTerm,
            isProgramLaunch,
            isSocial,
            isTraining,
            isWebinar,
            isOther,
            isOnsite,
            isVirtue,
            searchLocationTerm
        };
        let response = await searchEvents(inputs);
        setIsLoading(false);
        const eventsData = response?.events ?? [];
        setEvents(eventsData);
    }

    useEffect(() => {

        fetchEvents();

    }, []);

    const renderDuration = (event) => {
        let duration = "";
        let days = event.duration?.days ?? 0;
        let hours = event.duration?.hours ?? 0;
        let mins = event.duration?.mins ?? 0;
        if (days > 0) {
            duration = `${days} day${days === 1 ? '' : 's'}`;
        }
        if (hours > 0) {
            duration += `${duration.length > 0 ? ', ' : ''}${hours} hr${hours === 1 ? '' : 's'}`;
        }
        if (mins > 0) {
            duration += `${duration.length > 0 ? ', ' : ''}${mins} min${mins === 1 ? '' : 's'}`;
        }
        if (duration.length == 0) {
            return 'few mins';
        }
        return duration;
    };

    const renderAddress = (event) => {
        let address = event.address;
        if (typeof address === 'object') {
            address = `${address.city}, ${address.state} - ${address.zipcode}`
        }
        if (event.location_type === "virtual" || (typeof address === 'string' && address.length === 0)) {
            address = "Virtual Online Event";
        }
        return address;
    };

    return (
        <div className=" min-h-full flex flex-col bg-primary-gray">
            <NavHeader currentPage={"events"} />
            <div className="mx-auto max-w-6xl flex flex-col md:flex-row w-full items-start mt-[24px]  gap-x-5 px-[5px] md:px-4 py-[30px] md:py-8 ">
                <AsideLeft shows={'leaderboard'} />
                <AsideRight className="md:hidden" ads={ads} />
                <main className="flex-1 w-full">
                    <div className="flex-grow flex flex-col  ">
                        <div className="flex flex-col ">

                            <div className="flex flex-col mb-2">

                                <div className={`border-b rounded-t-md border-[#gray] flex flex-row ${isOrg ? "justify-between" : "justify-center"} items-center bg-primary-white flex flex-row flex-wrap text-sm font-bold pt-2 text-purple-900`}>

                                    <span className="pl-4">
                                        {isOrg ? `MY` : ``} EVENTS
                                    </span>

                                    {isOrg &&
                                        <a
                                            href="/events/add"
                                            className="pointer flex flex-row gap-x-[2px] cursor-pointer rounded-sm text-purple-900 px-3 py-1.5 text-md font-bold hover:text-purple-800 "
                                        >
                                            <EditIcon />
                                            Post Event
                                        </a>
                                    }
                                </div>

                                <form action="#" method="POST" onSubmit={handleSubmit} className="flex flex-col" >
                                    <div
                                        className="bg-primary-white flex flex-row flex-wrap justify-between  text-white  px-3 py-2 cursor-pointer">
                                        <div className="flex flex-col justify-between items-center flex-grow">
                                            <div className="flex flex-col md:flex-row  w-full ">
                                                <label htmlFor="search" className="sr-only">
                                                    Search Events
                                                </label>
                                                <div className="relative text-gray-400 focus-within:text-gray-600 w-full" >
                                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2  -top-[75px] md:-top-[0px] ">
                                                        <MagnifyingGlassIcon className="h-4 w-4" aria-hidden="true" />
                                                    </div>
                                                    <div className="flex flex-col md:flex-row ">
                                                        <input
                                                            id="searchevent"
                                                            className=" block w-full md:rounded-l-full  border-1 border-gray-200  bg-gray-300 py-0.5 pl-8 pr-3 text-black mb-0  focus:border-purple-900 focus:ring-0 focus:ring-purple-900  md:text-[0.8rem]  md:text-md leading-8 md:leading-6  disabled:cursor-progress"
                                                            placeholder="Title, Description ..."
                                                            type="search"
                                                            name="searchevent"
                                                            onChange={handleChange}
                                                            onKeyDown={handleKeyDown}
                                                            value={searchTerm}
                                                            disabled={isLoading}
                                                            aria-label="search for jobs by title, Company Name or Skill etc"
                                                        />
                                                        <input
                                                            id="searchlocationevent"
                                                            className="mt-1 md:mt-0 block w-full  border-1 border-gray-200  bg-gray-300 py-0.5 pl-8 pr-3 text-black mb-0  focus:border-purple-900 focus:ring-0 focus:ring-purple-900  md:text-[0.8rem] md:text-md leading-8 md:leading-6 disabled:cursor-progress"
                                                            placeholder="City, State, Zipcode ..."
                                                            type="search"
                                                            name="searchlocationevent"
                                                            onChange={handleLocationChange}
                                                            onKeyDown={handleKeyDown}
                                                            value={searchLocationTerm}
                                                            disabled={isLoading}
                                                            aria-label="search for events by location city, state, zipcode ..."
                                                        />
                                                    </div>
                                                    <button role="button"
                                                        type="submit"
                                                        disabled={isLoading}
                                                        className="app-xs-md-h cursor-pointer mt-[5px] md:mt-[0px]  w-full md:w-auto flex justify-center items-center text-white md:absolute right-0 border border-purple-900 top-[0px] py-1 bg-purple-800 hover:bg-purple-700 font-sm md:rounded-r-full text-md md:text-sm px-4 ">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="relative w-full">

                                                {errors.length > 0 &&
                                                    <div className="app-error text-red-400 font-bold text-xs px-2">
                                                        {errors[0]}
                                                    </div>
                                                }
                                                <div className="absolute  -top-6 right-20">
                                                    {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)', color: 'blue' }} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cursor-pointer accent-text-color"></div>
                                    </div>


                                    {/* filters */}
                                    <div className="bg-primary-white w-full py-2 flex flex-col justify-between font-sans text-purple-900 not-italic font-medium text-sm leading-[120%]   text-ellipsis overflow-hidden whitespace-nowrap border-b border-b-[#gray] gap-y-2 md:gap-y-none" >

                                        <div className="flex flex-row md:flex-row w-full items-start md:items-center justify-between gap-y-2 md:gap-y-none">
                                            <div className="flex flex-col md:flex-row gap-y-2 md:gap-y-none">
                                                <div className="flex items-center ml-3">
                                                    <input
                                                        id="filter-program_launch"
                                                        name="filter-program_launch"
                                                        type="checkbox"
                                                        className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                                        onChange={(e) => (e.target.checked) ? setIsProgramLaunch("true") : setIsProgramLaunch("false")}
                                                        value="true"
                                                        checked={"true" == isProgramLaunch}
                                                        disabled={isLoading}
                                                    />
                                                    <label htmlFor="filter-program_launch" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                                        Program Launch
                                                    </label>
                                                </div>
                                                <div className="flex items-center ml-3 ">
                                                    <input
                                                        id="filter-social"
                                                        name="filter-social"
                                                        type="checkbox"
                                                        className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                                        onChange={(e) => (e.target.checked) ? setIsSocial("true") : setIsSocial("false")}
                                                        value="true"
                                                        checked={"true" == isSocial}
                                                        disabled={isLoading}
                                                    />
                                                    <label htmlFor="filter-social" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-bold">
                                                        Social
                                                    </label>
                                                </div>
                                                <div className="flex items-center ml-3">
                                                    <input
                                                        id="filter-training"
                                                        name="filter-training"
                                                        type="checkbox"
                                                        className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                                        onChange={(e) => (e.target.checked) ? setIsTraining("true") : setIsTraining("false")}
                                                        value="true"
                                                        checked={"true" == isTraining}
                                                        disabled={isLoading}
                                                    />
                                                    <label htmlFor="filter-training" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                                        Training
                                                    </label>
                                                </div>
                                                <div className="flex items-center ml-3">
                                                    <input
                                                        id="filter-webinar"
                                                        name="filter-webinar"
                                                        type="checkbox"
                                                        className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                                        onChange={(e) => (e.target.checked) ? setIsWebinar("true") : setIsWebinar("false")}
                                                        value="true"
                                                        checked={"true" == isWebinar}
                                                        disabled={isLoading}
                                                    />
                                                    <label htmlFor="filter-webinar" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                                        Webinar
                                                    </label>
                                                </div>
                                                <div className="flex items-center ml-3">
                                                    <input
                                                        id="filter-other"
                                                        name="filter-other"
                                                        type="checkbox"
                                                        className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                                        onChange={(e) => (e.target.checked) ? setIsOther("true") : setIsOther("false")}
                                                        value="true"
                                                        checked={"true" == isOther}
                                                        disabled={isLoading}
                                                    />
                                                    <label htmlFor="filter-other" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                                        Other
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="flex flex-col md:flex-row gap-y-2 md:gap-y-none">
                                                <div className="flex items-center ml-3">
                                                    <input
                                                        id="filter-onsite"
                                                        name="filter-onsite"
                                                        type="checkbox"
                                                        className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                                        onChange={(e) => (e.target.checked) ? setIsOnSite("true") : setIsOnSite("false")}
                                                        value="true"
                                                        checked={"true" == isOnsite}
                                                        disabled={isLoading}
                                                    />
                                                    <label htmlFor="filter-onsite" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                                        Onsite
                                                    </label>
                                                </div>
                                                <div className="flex items-center ml-3">
                                                    <input
                                                        id="filter-virtual"
                                                        name="filter-virtual"
                                                        type="checkbox"
                                                        className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                                        onChange={(e) => (e.target.checked) ? setIsVirtue("true") : setIsVirtue("false")}
                                                        value="true"
                                                        checked={"true" == isVirtue}
                                                        disabled={isLoading}
                                                    />
                                                    <label htmlFor="filter-virtual" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                                        Virtual
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="flex flex-col md:flex-row gap-y-2 md:gap-y-none">

                                            </div>
                                        </div>

                                        <div className="flex flex-row md:flex-row items-center justify-start">

                                        </div>
                                    </div>


                                </form>

                                {isLoading &&
                                    <>
                                        <CommunityAccordionSkeleton></CommunityAccordionSkeleton>
                                        <CommunityAccordionSkeleton items={2}></CommunityAccordionSkeleton>
                                        <CommunityAccordionSkeleton items={3}></CommunityAccordionSkeleton>
                                    </>
                                }

                                {/* list of events */}
                                {!isLoading &&

                                    <div className={`flex flex-col bg-primary-white h-auto rounded-b-md`}  >
                                        {events.length == 0 &&
                                            <div className="flex justify-center py-12 text-gray-400 font-semibold lg:py-20">
                                                <div className="flex flex-col items-center text-xs sm:text-sm md:text-base">
                                                    <div>
                                                        No Events
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="rounded-b-md"  >
                                            {events.map((event, index) => (
                                                <EventListItem event={event} loggedInUser={loggedInUser} key={index} />
                                            ))}
                                            
                                            <LoginAlertModal isOpen={isLoginAlertModalOpen} closeFun={setIsLoginAlertModalOpen} />
                                        </div>

                                    </div>
                                }
                            </div>


                        </div>
                    </div>
                </main>
                <AsideRight className="hidden md:block" ads={ads} />
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default Events;

