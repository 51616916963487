import React, { useState, useEffect, Fragment, useRef } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import { addEvent, getEvent, editEvent, deleteEvent } from "../core/events";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { isAuthorised } from "../utils/auth";
import states from "../utils/states.json";
import timezones from "../utils/timezones";
import { Dialog, Transition } from '@headlessui/react';

const AddEventForm = () => {
    const navigate = useNavigate();

    let { id } = useParams();
    if (!id) {
        id = "";
    }

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }
    const isOrg = isAuthorised(["organisation"]);
    if (isOrg === false) {
        navigate("/events");
    }

    const DEFAULT_LOGO = "https://img.freepik.com/free-photo/sports-fans-huddle-tailgate-event_53876-127327.jpg?t=st=1724773319~exp=1724776919~hmac=58f474a0c1710ca8de7bbc0c94c4ed8751858c33867059a06a24373634c99f28&w=1800";
    const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL || "";
    const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "";


    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const [title, setTitle] = useState("");
    const [externalLink, setExternalLink] = useState("");
    const [eventType, setEventType] = useState("");
    const [locationType, setLocationType] = useState("");
    const [addressCity, setAddressCity] = useState("");
    const [addressState, setAddressState] = useState("");
    const [addressZipCode, setAddressZipCode] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [timezone, setTimezone] = useState("");
    const [media, setMedia] = useState(DEFAULT_LOGO);
    const [hostName, setHostName] = useState("");
    const [description, setDescription] = useState("");

    const clearForm = () => {
        setTitle("");
        setEventType("");
        setLocationType("");
        setAddressCity("");
        setAddressState("");
        setAddressZipCode("");
        setStartDate(new Date());
        setEndDate(new Date());
        setTimezone("");
        setMedia(DEFAULT_LOGO);
        setHostName("");
        setDescription("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            title,
            eventType,
            locationType,
            address: {
                city: addressCity,
                state: addressState,
                zipcode: addressZipCode
            },
            startDate,
            endDate,
            media,
            description,
            timezone,
            hostName,
            externalLink
        };
        let response = null;

        //normal post
        if (id === "") {
            response = await addEvent(inputs);
        } else if (id !== "" && id.length > 0) {
            response = await editEvent({ id: id, ...inputs });
        }
        setIsLoading(false);
        if (Array.isArray(response) || (Array.isArray(response) && response.length > 0 && typeof response[0] === 'string')) {
            return setErrors(response);
        }
        clearForm();
        toast.success('The event was added successfully', {
            icon: '✅'
        });
        setTimeout(() => {
            navigate(`/events`);
        }, 1000);
    }

    const [isFileUpLoading, setIsFileUpLoading] = useState(false);
    const handleOnFileChange = async (changeEvent) => {
        const reader = new FileReader();
        reader.onload = async function (onLoadEvent) {
            setIsFileUpLoading(true);
            setMedia(onLoadEvent.target.result);
            const fileInput = changeEvent.target;
            const formData = new FormData();
            formData.append('file', fileInput.files[0]);
            formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
            const data = await fetch(CLOUDINARY_URL, {
                method: "POST",
                body: formData
            })
                .then(r => r.json())
                .then((data) => {
                    setMedia(data.secure_url);
                    setIsFileUpLoading(false);
                });
        }
        if (reader && reader.readAsDataURL && changeEvent.target.files && changeEvent.target.files.length > 0) {
            reader.readAsDataURL(changeEvent.target.files[0]);
        }
    };

    //https://dev.to/kevinluo201/set-value-of-datetime-local-input-field-3435
    const convertToDateTimeLocalString = (date) => {
        if (typeof date == 'string') {
            date = new Date(date);
        }
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    const fetchData = async () => {
        const results = await getEvent(id);
        // console.log("results", results);
        setTitle(results.title);
        setExternalLink(results.external_link);
        setEventType(results.event_type);
        setLocationType(results.location_type);
        setAddressCity(results.address.city);
        setAddressState(results.address.state);
        setAddressZipCode(results.address.zipcode);
        setStartDate(convertToDateTimeLocalString(results.start_date));
        setEndDate(convertToDateTimeLocalString(results.end_date));
        setTimezone(results.timezone);
        setMedia(results.media);
        setHostName(results.host_name);
        setDescription(results.description);
    }

    const cancelButtonRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleDeleteModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const closeFun = () => {
        setIsModalOpen(false);
    }

    const handleDelete = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);
        const inputs = {
            id: id
        };
        let response = null;
        //normal post
        response = await deleteEvent(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        toast.success('The Event was deleted successfully', {
            icon: '✅'
        });
        navigate(`/events`);
    }

    useEffect(() => {
        if (id !== "") {
            fetchData();
        }
    }, []);

    return (
        <div className="flex min-h-full flex-col ">
            <NavHeader currentPage={"events"} />

            <div className=" mx-auto max-w-6xl flex flex-col md:flex-row w-full items-start mt-[24px] gap-x-5 px-[5px] md:px-4 py-[30px] md:py-10 ">
                <AsideLeft shows={'leaderboard'} />
                <AsideRight className="md:hidden" />
                <main className="flex-1 flex-grow  flex ">
                    <div className=" flex-grow flex flex-col flex-1 ">


                        <form action="#" method="POST" onSubmit={handleSubmit} className="bg-primary-white rounded-md  flex flex-1">

                            <div className="space-y-12 flex-1 flex">
                                <div className="flex-1 flex flex-col border border-gray-900/10 ">
                                    <h2 className="text-lg font-semibold  text-gray-900 px-4 pt-2 pb-0 mb-0 flex justify-between">
                                        {id !== "" ? 'Add' : 'Edit'} Event Form

                                        {id !== "" &&
                                            <button role="button"
                                                type="button"
                                                disabled={isLoading}
                                                className="rounded-sm bg-red-600 px-3 py-1 text-sm font-semibold text-white  hover:bg-red-500 disabled:cursor-not-allowed"
                                                onClick={toggleDeleteModal}
                                            >
                                                Delete
                                            </button>
                                        }
                                    </h2>
                                    <p className=" text-sm text-gray-600 px-4 pt-2 pt-0">
                                        Fill out this form and submit it to {id !== "" ? 'add' : 'edit'} an event
                                    </p>

                                    <div className="flex-1 flex flex-col justify-start items-start mt-2 py-5 px-4 gap-x-6 gap-y-4 border-t border-purple-900/10 bg-white">
                                        {/* title */}
                                        <div className="w-full">
                                            <label htmlFor="event-title" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                Title
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id="event-title"
                                                    required
                                                    value={title}
                                                    disabled={isLoading}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    className="mb-0 app-sm-md-h block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>
                                        {/* external link */}
                                        <div className="w-full">
                                            <label htmlFor="external-link" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                External Link
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="external-link"
                                                    id="external-link"
                                                    required
                                                    value={externalLink}
                                                    disabled={isLoading}
                                                    onChange={(e) => setExternalLink(e.target.value)}
                                                    className="mb-0 app-sm-md-h block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>
                                        {/* event type and location type */}
                                        <div className="w-full flex flex-row items-center gap-x-4">
                                            {/* event type */}
                                            <div className="w-1/2">
                                                <label htmlFor="event-type" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Event Type {eventType}
                                                    <span className="text-red-900 mx-1">*</span>
                                                </label>
                                                <div className="">
                                                    <select
                                                        id="event-type"
                                                        name="event-type"
                                                        className="mb-0 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                        required
                                                        value={eventType}
                                                        disabled={isLoading}
                                                        onChange={(e) => setEventType(e.target.value)}
                                                    >
                                                        <option value="" selected disabled >Select Event Type</option>
                                                        <option value={'program_launch'} selected={'program_launch' === eventType} >Program Launch</option>
                                                        <option value={'social'} selected={'social' === eventType} >Social</option>
                                                        <option value={'training'} selected={'training' === eventType} >Training</option>
                                                        <option value={'webinar'} selected={'webinar' === eventType} >Webinar</option>
                                                        <option value={'other'} selected={'other' === eventType} >Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {/* location type */}
                                            <div className="w-1/2">
                                                <label htmlFor="event-location-type" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Location Type 
                                                    <span className="text-red-900 mx-1">*</span>
                                                </label>
                                                <div className="">
                                                    <select
                                                        id="event-location-type"
                                                        name="event-location-type"
                                                        className="mb-0 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                        required
                                                        value={locationType}
                                                        disabled={isLoading}
                                                        onChange={(e) => setLocationType(e.target.value)}
                                                    >
                                                        <option value="" selected disabled >Select Location Type</option>
                                                        <option value={'onsite'}>On Site</option>
                                                        <option value={'virtual'}>Virtual</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {/* address */}
                                        {locationType === 'onsite' &&
                                            <>
                                                <div className="w-full">
                                                    <label htmlFor="city" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                        City
                                                        <span className="text-red-900 mx-1">*</span>
                                                    </label>
                                                    <div className="mt-0">
                                                        <input
                                                            id="city"
                                                            name="city"
                                                            type="text"
                                                            autoComplete=""
                                                            required
                                                            maxLength={100}
                                                            value={addressCity}
                                                            disabled={isLoading}
                                                            onChange={(e) => setAddressCity(e.target.value)}
                                                            className="app-sm-h app-mb-15  mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex flex-col md:flex-row w-full gap-x-2 pb-2">
                                                    <div className="w-1/2 flex flex-col flex-grow">
                                                        <label htmlFor="state" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                            State
                                                            <span className="text-red-900 mx-1">*</span>
                                                        </label>
                                                        <div className="mt-0">
                                                            <select
                                                                id="state"
                                                                name="state"
                                                                type="text"
                                                                autoComplete=""
                                                                required
                                                                value={addressState}
                                                                disabled={isLoading}
                                                                onChange={(e) => setAddressState(e.target.value)}
                                                                className="app-sm-h app-mb-15  first-option mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                            >
                                                                <option value="" key="first-state" selected disabled >Select State</option>
                                                                {states.map((state) => {
                                                                    return (
                                                                        <option value={state.abbreviation} key={state.abbreviation}>{state.name}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="w-1/2 flex flex-col md:w-[100px]">
                                                        <label htmlFor="zipcode" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                            Zip Code
                                                            <span className="text-red-900 mx-1">*</span>
                                                        </label>
                                                        <div className="mt-0">
                                                            <input
                                                                id="zipcode"
                                                                name="zipcode"
                                                                type="text"
                                                                autoComplete=""
                                                                required
                                                                value={addressZipCode}
                                                                disabled={isLoading}
                                                                maxLength={5}
                                                                onChange={(e) => setAddressZipCode(e.target.value)}
                                                                className="app-sm-h mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {/* start date, end date and timezone*/}
                                        <div className="w-full flex flex-row items-center gap-x-4">
                                            {/* start date */}
                                            <div className="w-1/2">
                                                <label htmlFor="event-start-date" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Start Date
                                                    <span className="text-red-900 mx-1">*</span>
                                                </label>
                                                <div className="">
                                                    <input
                                                        type="datetime-local"
                                                        id="event-start-date"
                                                        name="event-start-date"
                                                        className="mb-0 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                        required
                                                        value={startDate}
                                                        disabled={isLoading}
                                                        onChange={(e) => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            {/* end date */}
                                            <div className="w-1/2">
                                                <label htmlFor="event-end-date" className="block text-sm font-medium leading-6 text-gray-900">
                                                    End Date
                                                    <span className="text-red-900 mx-1">*</span>
                                                </label>
                                                <div className="">
                                                    <input
                                                        type="datetime-local"
                                                        id="event-end-date"
                                                        name="event-end-date"
                                                        className="mb-0 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                        required
                                                        value={endDate}
                                                        disabled={isLoading}
                                                        onChange={(e) => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* timezone */}
                                        <div className="w-full">
                                            <label htmlFor="timezone" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                Timezone
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-0">
                                                <select
                                                    id="timezone"
                                                    name="timezone"
                                                    required
                                                    value={timezone}
                                                    disabled={isLoading}
                                                    onChange={(e) => setTimezone(e.target.value)}
                                                    className="app-sm-h app-mb-15 first-option mb-0 block w-full rounded-sm border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                >
                                                    <option value="" key="first-timezone" selected disabled >Select Timezone</option>
                                                    {timezones.map((tz) => {
                                                        return (
                                                            <option value={`${tz.code}`} key={tz.name}>{`${tz.name}: ${tz.code}`}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        {/* description */}
                                        <div className="w-full">
                                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                Description
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-2">
                                                <textarea
                                                    id="description"
                                                    name="description"
                                                    rows={2}
                                                    value={description}
                                                    disabled={isLoading}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>

                                        {/* media */}
                                        <div className="pt-5 flex flex-col items-center w-full gap-x-3 ">

                                            <div className="pt-x flex flex-col justify-center ">
                                                <img
                                                    className="inline-block w-full rounded-sm"
                                                    src={media}
                                                    alt=""
                                                />
                                            </div>

                                            <div className="pt-2 flex flex-col flex-grow self-stretch justify-end items-start">
                                                <label htmlFor="photo" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                    Picture
                                                    <span className="text-red-900 mx-1">*</span>
                                                </label>

                                                <div className="flex items-center justify-center bg-grey-lighter mt-2">
                                                    <label disabled={isLoading} htmlFor="select-media" className={"flex flex-row items-center px-3 py-0.5 bg-white text-blue rounded-sm shadow-sm tracking-wide  border border-blue  hover:bg-purple-100 hover:text-gray-400 gap-x-2 " + (isLoading ? "cursor-not-allowed" : "cursor-pointer")}>
                                                        {!isFileUpLoading &&
                                                            <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                                            </svg>
                                                        }
                                                        <span className={`text-xs font-medium leading-6 text-gray-900 ${isFileUpLoading ? 'flex items-center pr-2 bg-purple-100' : ''} `}>
                                                            {isFileUpLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                            {!isFileUpLoading &&
                                                                <span>
                                                                    {(!media || media == DEFAULT_LOGO) ? 'Select' : 'Change'} event picture
                                                                </span>
                                                            }
                                                            {isFileUpLoading && "uploading"}
                                                        </span>
                                                        <input id="select-media" disabled={isLoading} name="select-media" type='file' className="hidden" onChange={handleOnFileChange} />
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        {/* host name */}
                                        <div className="w-full">
                                            <label htmlFor="event-host" className="app-sm-fs block text-sm font-medium leading-6 text-gray-900">
                                                Hosted By
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="host"
                                                    id="event-host"
                                                    required
                                                    value={hostName}
                                                    disabled={isLoading}
                                                    onChange={(e) => setHostName(e.target.value)}
                                                    className="app-sm-md-h block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="mt-2 py-4 px-4 border-t border-purple-900/10 flex flex-row justify-between ">
                                        <a
                                            href="/"
                                            className="flex justify-center cursor-pointer items-center flex-row w-1/4 rounded-sm bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed "
                                        >
                                            Cancel
                                        </a>

                                        <button role="button"
                                            type="submit"
                                            disabled={isLoading}
                                            className="app-sm-md-h app-sm-fs w-[200px] flex justify-center rounded-sm bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                        >
                                            {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                            Submit
                                        </button>
                                    </div>

                                    {errors.length > 0 &&
                                        <div className="app-error text-red-400 text-xs py-5 px-10">
                                            {errors[0]}
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                        {/* start delete confirmation prompt */}
                        <Transition.Root show={isModalOpen} as={Fragment}>
                            <Dialog as="div" className="relative " style={{ zIndex: 99 }} initialFocus={cancelButtonRef} open={isModalOpen} onClose={closeFun}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div onClick={() => closeFun(false)} className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
                                </Transition.Child>

                                <div className="fixed inset-0 z-10 overflow-y-auto">
                                    <div className="flex min-h-full md:items-center md:justify-center p-4 md:text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        >
                                            <Dialog.Panel className="h-[96vh] md:h-auto relative transform overflow-hidden  px-0 pb-0 pt-0 text-left shadow-xl transition-all w-[93vw]  md:w-full md:max-w-3xl md:mx-56">

                                                <div className="bg-white mx-0 md:mx-4 rounded-md">

                                                    <div className="bg-primary-white flex flex-row justify-between p-3 rounded-t-md  md:items-center ">
                                                        <div className="text-gray-900 text-md font-bold">
                                                            Delete This Event
                                                        </div>
                                                    </div>

                                                    <div className="overflow-x-auto rounded-b-sm p-5 text-lg">
                                                        This will permanently delete the event. Do you want to continue with this action ?
                                                    </div>

                                                    <div className="bg-white border-b border-gray-200 flex flex-row justify-between p-3 rounded-b-md items-center ">
                                                        <button role="button" 
                                                            onClick={(e)=>setIsModalOpen(false)}
                                                            className="flex justify-center cursor-pointer items-center flex-row w-1/4 rounded-sm bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed "
                                                        >
                                                            Cancel
                                                        </button>

                                                        <button role="button" 
                                                            type="button"
                                                            disabled={isLoading}
                                                            onClick={handleDelete}
                                                            className="w-[200px] flex justify-center rounded-sm bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 disabled:cursor-not-allowed"
                                                        >
                                                            {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                            Delete
                                                        </button>
                                                    </div>
                                                </div>

                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </Dialog>
                        </Transition.Root>
                        {/* end delete confirmation prompt */}
                    </div>
                </main>
                <AsideRight className="hidden md:block" />
            </div >
            <Footer />
            <GoUpDown />
        </div >
    );
};

export default AddEventForm;
