import React, { Fragment } from "react";
import { useState, useEffect } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import Bars3Icon from '../icons/Bars3Icon';
import XMarkIcon from '../icons/XMarkIcon';
import MagnifyingGlassIcon from "../icons/MagnifyingGlass";
import EventsIcon from "../icons/EventsIcon";
import NavLogo from "./NavLogo"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SelectForumModal from "../forum/SelectForumModal";
import UserDropDown from "./UserDropDown";
import InviteUserModal from "../leaderboard/InviteUserModal";
import { getSessionData } from "../../utils/auth";
import { logout } from "../../core/auth";
import NavUserAvatar from "./NavUserAvatar";
import PowerIcon from "../icons/PowerIcon";
import MobileMenuSearchModal from "./MobileMenuSearchModal";
import LoginAlertModal from "../auth/LoginAlertModal"
import { getCommunities, getJobsBudgesLastTime, getCommunityBudges, getAnonymousCommunityBudges } from "../../core/communities";
import { Utils } from "../../utils";
import { getJobListing } from "../../core/job_listing";
import BriefCase from "../icons/BriefCase";
import Globe from "../icons/Globe";
import StarIcon from "../icons/Star";
import EnvelopeOpen from "../icons/EnvelopeOpen";
import Ad from "../icons/Ad";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const NavHeader = ({ currentPage = "forums" }) => {

    let loggedInUser = getSessionData();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    let navigation = [
        { name: 'Communities', href: '/', active: currentPage == "forums", className: "", id: "menu-link-communities", budgeCount: 0, icon: Globe },
        { name: 'Jobs', href: '/jobs', active: currentPage == "jobs", className: "px-3", id: "menu-link-jobs", budgeCount: 0, icon: BriefCase },
        { name: 'Events', href: '/events', active: currentPage == "events", className: "px-3", id: "menu-link-events", budgeCount: 0, icon: EventsIcon }
    ];
    
    // if (loggedInUser !== null && loggedInUser.role === "organisation") {
    //     navigation.push({
    //         name: 'Post a Job', href: `/jobs/add`, active: currentPage == "addjob", className: ""
    //     });
    // }

    //{ name: 'About', href: '/about',  active: currentPage=="about"}


    const location = useLocation();
    const isRequest = location.pathname.indexOf("/community-request-form") == 0;
    const isPost = location.pathname.indexOf("/post/") > 0;
    const isForum = location.pathname.indexOf("/community/") == 0 && location.pathname.indexOf("/forum/") > 0 && isPost == false;

    const [isSelectForumModalOpen, setIsSelectForumModalOpen] = useState(false);
    const toggleSelectForumModal = () => {
        setIsSelectForumModalOpen(!isSelectForumModalOpen);
    };


    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    let initialTermValue = "";
    if (location.pathname.indexOf("/search") == 0) {
        initialTermValue = searchParams.get("term");
    }

    const [searchTerm, setSearchTerm] = useState(initialTermValue);
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            navigate(`/search?term=${searchTerm}`);
        }
    };



    const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
    const toggleInviteUserModal = () => {
        setIsInviteUserModalOpen(!isInviteUserModalOpen);
    };

    const goToLogin = () => {
        toggleLoginAlertModalModal();
        // navigate(`/login`);
    }


    const [isMobileSearchModalOpen, setIsMobileSearchModalOpen] = useState(false);
    const toggleMobileSearchModalModal = () => {
        setIsMobileSearchModalOpen(!isMobileSearchModalOpen);
    };

    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        logout();
        setIsLoading(false);
        navigate("/");
    };

    const [isLoginAlertModalOpen, setIsLoginAlertModalOpen] = useState(false);
    const toggleLoginAlertModalModal = () => {
        setIsLoginAlertModalOpen(!isLoginAlertModalOpen);
    };

    if (loggedInUser !== null && loggedInUser.role === "admin") {
        navigation.push({
            name: 'Ads', href: `/forumads`, active: currentPage == "forumads", className: "px-3", icon: Ad
        });
    }

    const [navigationObjects, setNavigationObjects] = useState(navigation);
    const mobileNavObjActivitiesPage = {
        name: 'Leader Boards', 
        href: '/leader-boards', 
        active: currentPage == "leaderBoard", 
        className: "px-3", 
        id: "menu-link-leader-board", 
        budgeCount: 0, 
        icon: StarIcon 
    };
    const fetchCountsData = async () => {

        let fromJobsDateTime = new Date();
        if (loggedInUser !== null) {
            // get the last time for tracking budges
            const lastJobsBudgesDateTime = await getJobsBudgesLastTime();
            let dateStr = lastJobsBudgesDateTime?.last_budges_jobs_date_time ?? '';
            dateStr = dateStr.trim();
            if (dateStr.length > 0) {
                fromJobsDateTime = new Date(dateStr);
            } else {
                const dateStrLocal = localStorage.getItem("last_budges_jobs_date_time");
                fromJobsDateTime = (dateStrLocal) ? new Date(dateStrLocal) : Utils.getLastWeeksDate();
            }
        } else {
            const dateStrLocal = localStorage.getItem("last_budges_jobs_date_time");
            fromJobsDateTime = (dateStrLocal) ? new Date(dateStrLocal) : Utils.getLastWeeksDate();
        }
        const jobListingCommunity = await getJobListing();
        let newJobsCount = 0;
        jobListingCommunity.jobs.forEach((job) => {
            if (job.createdAt && Utils.isLessThanMonth(job.createdAt, fromJobsDateTime)) {
                newJobsCount += 1;
            }
        });

        //communities budges
        let newPostsCount = 0;
        let communityBudges = {};
        if (loggedInUser !== null) {
            communityBudges = await getCommunityBudges();
        }else{
            communityBudges = await getAnonymousCommunityBudges();
        }
        for (const key in communityBudges) {
            if (Object.hasOwnProperty.call(communityBudges, key)) {
                newPostsCount =  newPostsCount + communityBudges[key].length;
            }
        }

        let navCopy = [];
        for (let index = 0; index < navigationObjects.length; index++) {
            const navOb = navigationObjects[index];
            if (navOb.name === "Communities") {
                navOb.budgeCount = newPostsCount;
            }
            if (navOb.name === "Jobs") {
                navOb.budgeCount = newJobsCount;
            }
            navCopy.push({ ...navOb });
        }
        setNavigationObjects(navCopy);
    }


    useEffect(() => {
        fetchCountsData();
    }, []);

    return (
        <header className="bg-primary-white fixed z-50 w-screen md:min-h-[50px] flex flex-row items-center justify-between">
            <LoginAlertModal message="Login to invite." isOpen={isLoginAlertModalOpen} closeFun={setIsLoginAlertModalOpen} />
            <nav className="hidden md:flex mx-auto flex max-w-6xl items-center  justify-between gap-x-5 py-1 flex-grow  lg:px-8  " aria-label="Global">
                <div className="w-56">
                    <NavLogo />
                </div>
                <div className="flex flex-grow justify-between">
                    <div className="flex  flex-row items-center gap-x-8 ">
                        {navigationObjects.map((item) => {
                            let cls = `flex flex-col items-center relative text-xs ${item.className} ${item.active ? 'accent-text-color active-menu-tab font-extrabold' : 'text-primary-gray-900 font-semibold'}`;
                            return (
                                <a key={item.name} href={item.href} className={cls} id={item.id}>
                                    {item.icon &&
                                        <span className="relative">
                                            {React.createElement(item.icon)}
                                            {item?.budgeCount > 0 &&
                                                <span className={`bg-red-600 max-h-[15px] flex items-center absolute p-1 text-white  rounded-full  -right-[8px]  ${item.active ? '-top-[5px]' : '-top-2'}`} style={{ fontSize: "10px" }} >
                                                    {item?.budgeCount}
                                                </span>
                                            }
                                        </span>
                                    }
                                    <span>
                                        {item.name}
                                    </span>
                                </a>
                            )
                        })}

                        {/*
                            removes add a post from menu
                        <button role="button" 
                            onClick={loggedInUser !== null ? toggleSelectForumModal : goToLogin}
                            className={`text-sm leading-6  ${currentPage == "addpost" ? "accent-text-color active-menu-tab font-extrabold" : "text-primary-gray-900 font-semibold"}`}
                        >
                            Create a Post
                        </button> */}

                        <button role="button" 
                            onClick={loggedInUser !== null ? toggleInviteUserModal : goToLogin}
                            className="flex flex-col items-center relative text-xs text-primary-gray-900 font-semibold"
                            id="menu-btn-invite"
                        >
                            <span className="relative">
                                <EnvelopeOpen />
                            </span>
                            <span>
                                Invite
                            </span>
                        </button>

                    </div>

                    <div className="flex">
                        <label htmlFor="search" className="sr-only">
                            Search
                        </label>
                        <div className="relative text-gray-400 focus-within:text-gray-600" >
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                            </div>
                            <input
                                id="search"
                                className="block w-[300px] rounded-full border-1 border-gray-200 bg-gray-300 py-0.5 pl-8 pr-3 text-gray mb-0 focus:border-purple-900 focus:ring-0 focus:ring-purple-900  sm:text-sm sm:leading-6"
                                placeholder="Search"
                                type="search"
                                name="search"
                                onKeyDown={handleKeyDown}
                                onChange={handleChange}
                                value={searchTerm}
                            />
                        </div>
                    </div>
                </div>
                {loggedInUser == null &&
                    <div className="flex items-center justify-end gap-x-6 ">

                        <a href="/login" id="menu-link-login" className="hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900">
                            Log In
                        </a>
                        <a
                            href="/register"
                            id="menu-link-signup"
                            className="rounded-sm bg-purple-900 px-3 py-1 text-sm font-semibold text-primary-white shadow-sm hover:bg-purple-800 "
                        >
                            Sign Up
                        </a>

                    </div>
                }

                {loggedInUser != null &&
                    <UserDropDown user={loggedInUser} currentPage={currentPage} />
                }


            </nav>
            <SelectForumModal isOpen={isSelectForumModalOpen} closeFun={setIsSelectForumModalOpen} />
            <InviteUserModal isOpen={isInviteUserModalOpen} closeFun={setIsInviteUserModalOpen} />



            <div className="-mr-1 flex justify-between  w-full py-2 px-1 md:hidden">
                <div className="w-30">
                    <NavLogo />
                </div>

                <div className="flex flex-grow  justify-end">
                    <div className="flex justify-between">
                        <div className="flex flex-row items-center gap-x-2 mr-2 ">
                            {[navigationObjects[0], navigationObjects[1], navigationObjects[2]].map((item) => {
                                let cls = `flex flex-col items-center relative text-sm ${item.className} ${item.active ? 'accent-text-color active-menu-tab font-extrabold' : 'text-primary-gray-900 font-semibold'}`;
                                return (
                                    <a key={item.name} href={item.href} className={cls} id={`mobile-${item.id}`}>
                                        {item.icon &&
                                            <span className="relative">
                                                {React.createElement(item.icon)}
                                                {item?.budgeCount > 0 &&
                                                    <span className={`bg-red-600 max-h-[15px] flex items-center absolute p-1 text-white  rounded-full  -right-[8px]  ${item.active ? '-top-[5px]' : '-top-[5px]'}`} style={{ fontSize: "10px" }} >
                                                        {item?.budgeCount}
                                                    </span>
                                                }
                                            </span>
                                        }
                                        <span>
                                            {item.name}
                                        </span>
                                    </a>
                                )
                            })}

                            <button id="menu-btn-mobile-search" onClick={toggleMobileSearchModalModal} className="p-2 m-0">
                                <MagnifyingGlassIcon className="h-4 w-4" aria-hidden="true" /> 
                                <span className="sr-only">open search form</span>
                            </button>
                            <MobileMenuSearchModal isOpen={isMobileSearchModalOpen} closeFun={setIsMobileSearchModalOpen} />
                        </div>

                    </div>



                    <button role="button" 
                        type="button"
                        className="-m-2.5 flex items-center justify-center rounded-md p-2.5 text-primary-gray-700"
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        id="menu-btn-mobile-menu-hamburger"
                    >
                        <span className="sr-only">Open main menu</span>
                        {mobileMenuOpen &&
                            <XMarkIcon aria-hidden="true" />
                        }
                        {!mobileMenuOpen &&
                            <Bars3Icon aria-hidden="true" />
                        }
                    </button>

                </div>


            </div>
            <Dialog as="div" className="md:hidden " open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed top-[48px] inset-y-0 h-fit right-0 z-10 w-full overflow-y-auto bg-primary-white shadow-lg  px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-primary-gray-900/10">
                    <div className=" flow-root">
                        <div className="-my-6 divide-y divide-primary-gray-500/10">
                            <div className="flex flex-col gap-y-5 py-6">
                                {loggedInUser != null &&
                                    <div as="div" className="relative inline-block text-left">
                                        <div>
                                            <div className="inline-flex flex flex-col items-center w-full justify-center rounded-sm bg-primary-white text-sm font-semibold text-gray-900 shadow-xs xring-1 ring-inset ring-gray-200 hover:bg-orange-50">
                                                <NavUserAvatar user={loggedInUser} />
                                                <div className='mx-2'>
                                                    <div>
                                                        <p className="text-sm font-bold text-gray-700 group-hover:text-gray-900">{loggedInUser.username}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {[...navigation.slice(2), mobileNavObjActivitiesPage].map((item) => {
                                    let cls = `text-sm leading-6 ${item.active ? 'accent-text-color active-menu-tab font-extrabold' : 'text-primary-gray-900 font-semibold mt-0 '}`;
                                    return (
                                        <a key={item.name} href={item.href} className={cls} id={item.id} >
                                            {item.name}
                                        </a>
                                    )
                                })}

                                {/* <button role="button" 
                                    onClick={loggedInUser !== null ? toggleSelectForumModal : goToLogin}
                                    className={`text-sm leading-6 text-left mt-5 ${currentPage == "addpost" ? "accent-text-color active-menu-tab font-extrabold" : "text-primary-gray-900 font-semibold"}`}
                                >
                                    Add Post
                                </button> */}

                                <button role="button" 
                                    onClick={loggedInUser !== null ? toggleInviteUserModal : goToLogin}
                                    className="text-sm leading-6 text-left mt-1 text-primary-gray-900 font-bold "
                                    id="menu-btn-invite"
                                >
                                    Invite
                                </button>

                                {loggedInUser == null &&
                                    <div className="flex items-center justify-between gap-x-6 ">

                                        <a href="/login" id="menu-link-login" className="rounded-sm bg-purple-900 px-3 py-1 text-sm font-semibold text-primary-white shadow-sm hover:bg-purple-800 ">
                                            Log In
                                        </a>
                                        <a
                                            href="/register"
                                            id="menu-link-signup"
                                            className="rounded-sm bg-purple-900 px-3 py-1 text-sm font-semibold text-primary-white shadow-sm hover:bg-purple-800 "
                                        >
                                            Sign Up
                                        </a>

                                    </div>
                                }

                                {loggedInUser != null &&
                                    <>
                                        <a
                                            href={`/profile/${loggedInUser._id}`}
                                            className={classNames(
                                                currentPage === 'profile' ? 'accent-text-color active-menu-tab font-extrabold ' : 'text-gray-700',
                                                'block py-2 text-sm hover:bg-orange-50 text-primary-gray-900 font-semibold'
                                            )}
                                            id="menu-link-user-profile"
                                        >
                                            User Profile
                                        </a>

                                        <a
                                            href="/community-request-form"
                                            className={classNames(
                                                currentPage === 'notifications' ? 'accent-text-color active-menu-tab font-extrabold ' : 'text-gray-700',
                                                'block py-2 text-sm hover:bg-orange-50 text-primary-gray-900 font-semibold'
                                            )}
                                            id="menu-link-community-requests"
                                        >
                                            Community Requests
                                        </a>

                                        <a
                                            href="/profile/password"
                                            className={classNames(
                                                currentPage === 'password' ? 'accent-text-color active-menu-tab font-extrabold ' : 'text-gray-700',
                                                'block py-2 text-sm hover:bg-orange-50 text-primary-gray-900 font-semibold'
                                            )}
                                            id="menu-link-update-password"
                                        >
                                            Update Password
                                        </a>

                                        <a
                                            href="/support"
                                            className={classNames(
                                                currentPage === 'support' ? 'accent-text-color active-menu-tab font-extrabold' : 'text-gray-700',
                                                'block py-2 text-sm hover:bg-orange-50 text-primary-gray-900 font-semibold'
                                            )}
                                            id="menu-link-support"
                                        >
                                            Support
                                        </a>

                                        <div className="py-0">
                                            <form method="POST" action="#" onSubmit={handleSubmit}>
                                                <div>
                                                    <button role="button" 
                                                        id="menu-btn-sign-out"
                                                        type="submit"
                                                        className="block w-full flex gap-x-1 items-center py-2 text-left text-sm font-bold hover:bg-red-100 hover:text-red-900"
                                                    >
                                                        <PowerIcon aria-hidden="true" />
                                                        Sign Out
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                }


                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}

export default NavHeader;
