import React, { useRef, useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import PostHeroCard from "../components/post/PostHeroCard";
import labData from "../utils/labData";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import { useSearchParams } from "react-router-dom";
import { Utils } from "../utils";
import OrderByDescending from "../components/icons/OrderByDescending";
import Pagination from "../components/pagination/Pagination";
import ChevronRightIcon from "../components/icons/ChevronRight";
import { generalSearch } from "../core/communities";
import PinIcon from "../components/icons/Pin";
import DollarCirle from "../components/icons/DollarCirle";
import { useNavigate } from "react-router-dom";
import ForumAvatar from "../components/forum/ForumAvatar";
import IdentityIcon from "../components/icons/Identity";
import ChatsIcon from "../components/icons/Chats"
import ReactTimeAgo from 'react-time-ago'
import JobsSkeleton from "../components/skeletons/JobsSkeleton";
import PostListItem from "../components/post/PostListItem";
import EventListItem from "../components/events/EventListItem";

const Search = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    let searchTerm = searchParams.get("term");
    const [isLoading, setIsLoading] = useState(false);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState({
        length: 0
    });
    const [pageSize, setPageSize] = useState(100);
    const [searchResults, setSearchResults] = useState([]);

    const fetchData = async () => {
        setIsLoading(true);
        const data = await generalSearch({
            searchTerm: searchTerm
        });
        setIsLoading(false);
        console.log("search results", data);
        setSearchResults(data);
    }

    useEffect(() => {
        fetchData();
    }, [searchParams]);

    const handleOpenForum = (e, communityId, forumId) => {
        e.preventDefault();
        navigate(`/community/${communityId}/forum/${forumId}`);
    }


    return (
        <div className="flex min-h-full flex-col bg-[#dae0e6]">
            <NavHeader currentPage={"search"} />
            <div className=" mx-auto flex flex-col md:flex-row w-full items-start mt-[48px] md:mt-[28px] max-w-6xl gap-x-5 px-[5px] md:px-4 py-[5px] md:py-10 8">
                <AsideLeft shows={'leaderboard'} />
                <AsideRight className="md:hidden" />
                <main className="flex-1 w-full">
                    {isLoading &&
                        <JobsSkeleton />
                    }
                    {isLoading === false &&
                        <div className="flex-grow flex flex-col rounded-md  ">
                            <div className="flex justify-between items-center bg-purple-900 rounded-t-md ">
                                <div
                                    className="rounded-sm  px-3 py-1.5 text-sm font-semibold text-white shadow-sm w-full flex flex-row items-center justify-between "
                                >
                                    <span className="">{searchTerm}</span>

                                    {searchResults.length > 0 &&
                                        <span>
                                            <b className="text-purple-200 mr-1">{searchResults.length}</b>
                                            Result{searchResults.length > 1 ? 's' : ''}
                                        </span>
                                    }
                                </div>

                                {/* <button role="button"  type="button" className="relative inline-flex items-center rounded-sm bg-gray-300 px-5 py-0.5 my-2 mr-2 font-semibold text-purple-900 hover:bg-gray-200 ">
                                    <OrderByDescending />
                                </button> */}

                            </div>
                            <ul role="list" className="divide-y divide-gray-200 bg-white rounded-b-md">
                                {searchResults.map((result, index) => {
                                    if (result.result_type === "job") {
                                        const job = result;
                                        return (
                                            <a key={index}
                                                href={`/jobs/${job._id}`}
                                                className="last:rounded-b-md flex flex-col  border-gray-200 px-3 pt-3 pb-3 bg-primary-white cursor-pointer">
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-[48px] h-[48px]  flex justify-center items-center text-purple-300">
                                                        <img
                                                            className="inline-block w-[48px] h-[48px] rounded-sm"
                                                            src={job.user.avatar}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="flex-grow flex flex-col justify-between ml-2">
                                                        <div className="flex flex-row justify-between items-center">
                                                            <div className="text-xs text-gray-500 truncate text-md ">
                                                                {job.user.username}
                                                            </div>
                                                            <div className="flex items-center justify-between">
                                                                <div className="shrink-0 text-xxs text-gray-500 text-xs md:text-md">
                                                                    {Utils.formatDate(job.createdAt, "month date, year")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row justify-between items-center">
                                                            <span className="text-sm font-semibold font-medium">
                                                                {job.title}
                                                            </span>
                                                        </div>
                                                        <div className="text-xs font-semibold text-gray-500 sm:flex sm:items-center sm:justify-between md:text-xs">
                                                            <div className="flex flex-col gap-y-2 justify-start">
                                                                <div className="flex items-start text-purple-900 mr-5 truncate">
                                                                    <PinIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                                                                    {job.location.city}, &nbsp;{job.location.state} {job.location.zipcode}
                                                                </div>
                                                                <div className="flex items-center text-xs">
                                                                    <DollarCirle className="h-4 w-4 mr-1" aria-hidden="true" />
                                                                    {job.compesation}
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 text-xs flex items-center font-normal space-x-1 sm:mt-0">
                                                                <div className="ml-2 flex-shrink-0 flex">
                                                                    {job.job_type == "full-time" &&
                                                                        <p className="bg-green-200 text-green-700 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                                            Full time
                                                                        </p>
                                                                    }
                                                                    {job.job_type == "part-time" &&
                                                                        <p className="bg-purple-200 text-purple-700 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                                            Part Time
                                                                        </p>
                                                                    }
                                                                    {job.job_type == "contract-time" &&
                                                                        <p className="bg-yellow-200 text-yellow-700 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                                            Contract
                                                                        </p>
                                                                    }
                                                                </div>



                                                                <div className="ml-2 flex-shrink-0 flex">
                                                                    {job.location_type.toLowerCase() == "on-site" &&
                                                                        <p className="bg-cyan-200 text-cyan-700 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                                            On Site
                                                                        </p>
                                                                    }
                                                                    {job.location_type.toLowerCase() == "hybrid" &&
                                                                        <p className="bg-orange-200 text-orange-700 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                                            Hybrid
                                                                        </p>
                                                                    }
                                                                    {job.location_type.toLowerCase() == "remote" &&
                                                                        <p className="bg-lime-200 text-lime-700 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                                            Remote
                                                                        </p>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        )
                                    } else if (result.result_type === "post") {
                                        const post = result;
                                        return (
                                            <PostListItem isSearchResult={true} post={post} key={index} communityId={post.community} forumId={post.forum_id} />
                                        )
                                    } else if (result.result_type === "event") {
                                        const event = result;
                                        return (
                                            <EventListItem isSearchResult={true} event={event} loggedInUser={loggedInUser} key={index} />
                                        )
                                    } else {
                                        const forum = result;
                                        return (
                                            <div key={index} onClick={(e) => handleOpenForum(e, forum.community_id, forum._id)} className="last:rounded-b-md cursor-pointer flex flex-col md:flex-row justify-between  border-gray-200 px-1 pr-4 py-4  even:bg-white odd:bg-slate-100 hover:bg-purple-100 cursor-pointer ">
                                                <div className="flex flex-row items-center ">
                                                    <div className="w-[48px] h-[48px] bg-purple-900x flex justify-center items-center text-purple-300">
                                                        <ForumAvatar forum={forum} />
                                                    </div>
                                                    <div className="flex-grow flex flex-col justify-center ml-2 gap-y-0">
                                                        <div className="leading-[0.7]">
                                                            <span className="text-sm font-semibold font-medium ">
                                                                <a className="hover:underline cursor-pointer "
                                                                    href={`/community/${forum.community_id}/forum/${forum._id}`}>
                                                                    {forum.title}
                                                                </a>
                                                            </span>
                                                        </div>
                                                        <div className="text-muted flex flex-row items-center">
                                                            <IdentityIcon />
                                                            <span className="ml-1 mr-3 text-sm">{Utils.comma(forum.count_of_posts)}</span>
                                                            <ChatsIcon />
                                                            <span className="mx-1 text-sm">{Utils.comma(forum.count_of_replies)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {forum.latest_post &&
                                                    <div className="md:ml-14 mt-2 md:mt-0 md:ml-0 border-t border-[#gray] md:border-0 pt-2 md:pt-0 flex flex-col justify-center items-start">
                                                        <div className="flex text-sm">
                                                            <div>
                                                                Latest Post:
                                                            </div>
                                                            <div className="truncate max-w-[200px] text-purple-900 ml-1">
                                                                <a className="hover:underline cursor-pointer"
                                                                    href={`/community/${forum.community_id}/forum/${forum._id}/post/${forum.latest_post.post_id}`} >
                                                                    {forum.latest_post?.title}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <span className="text-xs capitalize font-semibold">
                                                                <a className="hover:underline cursor-pointer">
                                                                    {forum.latest_post ? forum.latest_post.user.username + ", " : ""}
                                                                </a>
                                                            </span>
                                                            <span className="text-sm ml-2">
                                                                <ReactTimeAgo date={forum.latest_post.date_of_post && forum.latest_post.date_of_post.length > 0 ? forum.latest_post.date_of_post : (new Date()).toDateString()} locale="en-US" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                })}

                                {searchResults.length == 0 &&
                                    <div className="flex justify-center py-12 text-gray-400 font-semibold rounded-b-md lg:py-20 bg-white" >
                                        <div className="flex flex-col items-center text-xs sm:text-sm md:text-base">
                                            <div>
                                                No Search Results
                                            </div>
                                        </div>
                                    </div>
                                }
                            </ul>

                            <div className="py-1 mb-5 text-sm ">
                                <Pagination
                                    id="pgn-bottom"
                                    currentPage={currentPage}
                                    totalCount={data.length}
                                    pageSize={pageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    }
                </main>
                <AsideRight className="hidden md:block" />
            </div>
            <Footer />
            <GoUpDown />
        </div>
    );
};

export default Search;