import React, { Fragment, useRef, useState, useEffect } from "react";
import NavHeader from "../components/navigation/NavHeader";
import Footer from "../components/navigation/Footer";
import GoUpDown from "../components/navigation/GoUpDown";
import AsideLeft from "../components/aside/AsideLeft";
import AsideRight from "../components/aside/AsideRight";
import { Editor } from '@tinymce/tinymce-react';
import { useParams } from "react-router-dom";
import { getPost, getForum, updatePost, deletePost } from "../core/communities";
import { useNavigate } from "react-router-dom";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import DOMPurify from "dompurify";
import { Dialog, Transition } from '@headlessui/react';
import ForumHeaderSection from "../components/forum/ForumHeaderSection";


const EditPostForm = () => {
    const { comid, id, pid } = useParams();
    const navigate = useNavigate();

    const TINY_MCE_API_KEY = process.env.REACT_APP_TINY_MCE;
    const editorRef = useRef(null);

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [editorKey, setEditorKey] = useState(4);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const [post, setPost] = useState(null);
    const [title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [details, setDetails] = useState("<p></p>");
    const [breadcrumbPages, setBreadcrumbPages] = useState([]);
    const [forum, setForum] = useState(null);

    const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL || "";
    const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "";
    const [isFileUpLoading, setIsFileUpLoading] = useState(false);
    const [media, setMedia] = useState("");

    useEffect(() => {
        const fetchPost = async () => {
            const postRes = await getPost(pid);
            setPost(postRes);
            setTitle(postRes.title);
            setSubTitle(postRes.sub_title);
            setMedia(postRes?.media ?? "");

            const details = postRes.description;
            const decodedDetails = atob(details);
            let html = DOMPurify.sanitize(decodedDetails, {
                USE_PROFILES: { html: true },
            });
            html = decodeURIComponent(html);

            setDetails(html);

            setBreadcrumbPages([
                { name: postRes?.forum?.community?.title, href: `/`, current: false },
                { name: postRes?.forum?.title, href: `/community/${postRes?.forum?.community._id}/forum/${postRes?.forum._id}`, current: false },
                { name: postRes?.title, href: `/community/${postRes?.forum?.community._id}/forum/${postRes?.forum._id}/post/${postRes?._id}`, current: false },
                { name: "Edit Post", href: ``, current: true },
            ]);
            setForum(postRes?.forum);
        }
        fetchPost();
    }, []);

    const clearForm = () => {
        setTitle(post.title);
        setSubTitle(post.sub_title);
        setMedia(post?.media ?? "");

        const details = post.description;
        const decodedDetails = atob(details);
        let html = DOMPurify.sanitize(decodedDetails, {
            USE_PROFILES: { html: true },
        });
        html = decodeURIComponent(html);
        setDetails(html);
        setEditorKey(editorKey * 2);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            id: post._id,
            title,
            subTitle,
            details,
            media
        };
        let response = null;
        response = await updatePost(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        toast.success('Post was updated successfully', {
            icon: '✅'
        });

        navigate(`/community/${comid}/forum/${id}/post/${pid}`);
    }

    const cancelButtonRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleDeleteModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const closeFun = () => {
        setIsModalOpen(false);
    }
    const goToLogin = () => {
        navigate(`/login`);
    }

    const handleDelete = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            id: post._id
        };
        let response = null;
        response = await deletePost(inputs);
        setIsLoading(false);
        if (response !== true) {
            return setErrors(response);
        }
        clearForm();
        toast.success('The Post was deleted successfully', {
            icon: '✅'
        });
        navigate(`/community/${comid}/forum/${id}`);
    }

    const handleOnFileChange = async (changeEvent) => {
        const reader = new FileReader();
        reader.onload = async function (onLoadEvent) {
            setIsFileUpLoading(true);
            setMedia(onLoadEvent.target.result);
            //https://www.youtube.com/watch?v=7lhUsK-FxYI
            // const form = e.currentTarget
            // const fileInput = Array.from(form.elements).find(({name}) => name == 'select-image');
            const fileInput = changeEvent.target;
            const formData = new FormData();
            formData.append('file', fileInput.files[0]);
            formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
            const data = await fetch(CLOUDINARY_URL, {
                method: "POST",
                body: formData
            })
                .then(r => r.json())
                .then((data) => {
                    setMedia(data.secure_url);
                    setIsFileUpLoading(false);
                });
        }
        if (reader && reader.readAsDataURL && changeEvent.target.files && changeEvent.target.files.length > 0) {
            reader.readAsDataURL(changeEvent.target.files[0]);
        }
    };

    return (
        <div className="flex min-h-full flex-col bg-[#dae0e6]">
            <NavHeader currentPage={"addpost"} />
            <div className="mt-[50px]">
                {forum &&
                    <ForumHeaderSection
                        forum={forum}
                        breadcrumbPages={breadcrumbPages}
                    />
                }
            </div>
            <div className=" mx-auto flex flex-col md:flex-row w-full items-start  max-w-6xl gap-x-5 px-[5px] md:px-4 py-[10px] md:py-4">
                <AsideLeft shows={'leaderboard'} />
                <AsideRight className="md:hidden" />
                <main className="flex-1 w-full">

                    {/* <ForumHeroSection forum={forum} /> */}

                    <div className="flex-grow flex flex-col w-full  ">

                        <form action="#" method="POST" onSubmit={handleSubmit} className="bg-primary-white rounded-md drop-shadow-md">
                            <div className="space-y-12">
                                <div className="border border-gray-900/10 rounded-md ">
                                    <h2 className="text-lg font-semibold rounded-md  text-gray-900 px-4 pt-2 pb-0 mb-0 flex justify-between">
                                        Edit Post Form
                                        {/* {(loggedInUser && post && (loggedInUser._id === post.user._id || loggedInUser.role === "admin")) &&
                                            <button role="button" 
                                                type="button"
                                                disabled={isLoading}
                                                className="rounded-sm bg-red-600 px-3 py-1 text-sm font-semibold text-white  hover:bg-red-500 disabled:cursor-not-allowed"
                                                onClick={toggleDeleteModal}
                                            >
                                                Delete
                                            </button>
                                        } */}
                                    </h2>
                                    <p className=" text-sm text-gray-600 px-4 pt-2 pt-0">
                                        Fill out and submit this form to update the post.
                                    </p>

                                    <div className="mt-2 py-5 px-4 grid grid-cols-1 gap-x-6 gap-y-1 grid-cols-6 border-t border-purple-900/10 ">
                                        {/* title */}
                                        <div className="col-span-full">
                                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                                                <b>Title</b>
                                                <span className="text-red-900 mx-1">*</span>
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    autoComplete="title"
                                                    value={title}
                                                    disabled={isLoading}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div>
                                        {/* sub title */}
                                        {/* <div className="col-span-full">
                                            <label htmlFor="sub-title" className="block text-sm font-medium leading-6 text-gray-900">
                                                <b>Sub Title</b>
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="sub-title"
                                                    id="sub-title"
                                                    value={subTitle}
                                                    disabled={isLoading}
                                                    onChange={(e) => setSubTitle(e.target.value)}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:cursor-progress"
                                                />
                                            </div>
                                        </div> */}
                                        {/* media */}
                                        <div className="col-span-full mb-4">

                                            <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                                <b>Image</b>
                                            </label>

                                            <div className="flex items-start justify-start bg-grey-lighter mt-2">
                                                <label disabled={isLoading} htmlFor="select-image" className={"flex flex-row items-center px-3 py-0.5 bg-white text-blue rounded-sm shadow-sm tracking-wide  border border-blue  hover:bg-purple-100 hover:text-gray-400 gap-x-2 " + (isLoading ? "cursor-not-allowed" : "cursor-pointer")}>
                                                    {!isFileUpLoading &&
                                                        <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                                        </svg>
                                                    }
                                                    <span className={`text-xs font-medium leading-6 text-gray-900 ${isFileUpLoading ? 'flex items-center pr-2 bg-purple-100' : ''} `}>
                                                        {isFileUpLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                        {!isFileUpLoading &&
                                                            <span>
                                                                {(!media || media.length === 0) ? 'Upload' : 'Upload'}  Picture
                                                            </span>
                                                        }
                                                        {isFileUpLoading && "uploading"}
                                                    </span>
                                                    <input id="select-image" disabled={isLoading} name="select-image" type='file' className="hidden" onChange={handleOnFileChange} />
                                                </label>
                                            </div>

                                            {media.length > 0 &&
                                                <div className="flex flex-col mt-2 m" >
                                                    <img
                                                        className="inline-block max-h-[350px] rounded-sm"
                                                        src={media}
                                                        alt=""
                                                    />
                                                </div>
                                            }
                                        </div>
                                        {/* description */}
                                        <div className="col-span-full">
                                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                                <b>Description</b>
                                                <span className="text-red-900 mx-1">*</span>
                                                <span className="mt-3 ml-2 text-xs leading-1 text-gray-400">
                                                    - Provide details of your post
                                                </span>
                                            </label>
                                            <div className="mt-2">
                                                <Editor
                                                    key={'editor1-' + editorKey}
                                                    apiKey={TINY_MCE_API_KEY}
                                                    onInit={(evt, editor) => editorRef.current = editor}
                                                    onEditorChange={(text) => setDetails(text)}
                                                    value={details}
                                                    init={{
                                                        height: 250,
                                                        menubar: false,
                                                        plugins:
                                                            'advlist autolink lists link image  anchor ' +
                                                            'searchreplace visualblocks ' +
                                                            'media table past wordcount'
                                                        ,
                                                        toolbar: 'undo redo | formatselect | ' +
                                                            'bold italic backcolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | outdent indent | ' +
                                                            'bullist numlist',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="mt-2 py-4 px-4 border-t border-purple-900/10 flex flex-row justify-between ">

                                        <a
                                            href={`/community/${comid}/forum/${id}/post/${pid}`}
                                            className="flex justify-center cursor-pointer items-center flex-row w-1/4 rounded-sm bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed "
                                        >
                                            Cancel
                                        </a>

                                        <button role="button"
                                            type="submit"
                                            disabled={isLoading}
                                            className="w-[200px] flex justify-center rounded-sm bg-purple-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 disabled:cursor-not-allowed"
                                        >
                                            {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                            Submit
                                        </button>

                                    </div>

                                    {errors.length > 0 &&
                                        <div className="app-error text-red-400 font-bold text-xs py-5 px-10">
                                            {errors[0]}
                                        </div>
                                    }

                                </div>
                            </div>
                        </form>

                    </div>
                </main>
                <AsideRight className="hidden md:block" />
            </div>
            <Footer />
            <GoUpDown />

            <Transition.Root show={isModalOpen} as={Fragment}>
                <Dialog as="div" className="relative " style={{ zIndex: 99 }} initialFocus={cancelButtonRef} open={isModalOpen} onClose={closeFun}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div onClick={() => closeFun(false)} className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full md:items-center md:justify-center p-4 md:text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden  px-0 pb-0 pt-0 text-left shadow-xl transition-all w-[93vw]  md:w-full md:max-w-3xl md:mx-56">

                                    <div className="bg-white mx-4 rounded-md">

                                        <div className="bg-primary-white border-b border-gray-200 flex flex-row justify-between p-3 rounded-t-md items-center ">
                                            <div className="text-gray-900 text-md font-bold">
                                                Delete This Post
                                            </div>
                                        </div>

                                        <div className="overflow-x-auto rounded-b-sm p-5 text-lg">
                                            Deleting the post deletes all its comments and likes. Do you want to continue with this action ?
                                        </div>

                                        <div className="bg-white border-b border-gray-200 flex flex-row justify-between p-3 rounded-b-md items-center ">
                                            <button role="button"
                                                onClick={(e) => setIsModalOpen(false)}
                                                className="flex justify-center cursor-pointer items-center flex-row w-1/4 rounded-sm bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed "
                                            >
                                                Cancel
                                            </button>

                                            <button role="button"
                                                type="button"
                                                disabled={isLoading}
                                                onClick={handleDelete}
                                                className="w-[200px] flex justify-center rounded-sm bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 disabled:cursor-not-allowed"
                                            >
                                                {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />}
                                                Delete
                                            </button>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
};

export default EditPostForm;
