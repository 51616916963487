import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import ChevronDownIcon from "../icons/ChevronDown";
import NavUserAvatar from "./NavUserAvatar";
import PowerIcon from "../icons/PowerIcon";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { logout } from "../../core/auth";
import toast, { Toaster } from 'react-hot-toast';
import { getSessionData } from "../../utils/auth";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function UserDropDown({ user, currentPage }) {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        logout();
        setIsLoading(false);
        navigate("/");
    };

    let loggedInUser = getSessionData();

    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button id="menu-btn-user-dropdown" className="inline-flex w-full justify-center rounded-sm bg-primary-white text-sm font-semibold text-gray-900 shadow-xs xring-1 ring-inset ring-gray-200 hover:bg-orange-50">
                        <div className='mx-2'>
                            <div>
                                <p className={`text-sm font-bold ${user?.role === "admin"?'text-red-700 group-hover:text-red-900': 'text-gray-700 group-hover:text-gray-900'}`} >{user.username}</p>
                            </div>
                            <div className='p-0 flex justify-center'>
                                <ChevronDownIcon className="w-5 w-5 text-gray-400" />
                            </div>
                        </div>
                        <NavUserAvatar user={user} />
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-primary-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-4 py-3">
                            <p className="text-sm mb-2 text-gray-400">Signed in as</p>
                            <p className="truncate text-md font-bold text-gray-900 flex gap-x-3 items-center">
                                {user?.username} 
                                {user?.role === "admin" && 
                                    <small className='font-normal'>[admin]</small>
                                }
                            </p>
                        </div>
                        <div className="py-0">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href={`/profile/${loggedInUser._id}`}
                                        className={classNames(
                                            currentPage === 'profile' ? 'bg-gray-100 text-purple-900 font-semibold ' : 'text-gray-700',
                                            'block px-4 py-2 text-sm hover:bg-orange-50'
                                        )}
                                        id="menu-link-user-profile"
                                    >
                                        User Profile
                                    </a>
                                )}
                            </Menu.Item>

                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="/community-request-form"
                                        className={classNames(
                                            currentPage === 'notifications' ? 'bg-gray-100 text-purple-900 font-semibold ' : 'text-gray-700',
                                            'block px-4 py-2 text-sm hover:bg-orange-50'
                                        )}
                                        id="menu-link-community-requests"
                                    >
                                        Community Requests
                                    </a>
                                )}
                            </Menu.Item>
                            {/* <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                            currentPage === 'notifications' ? 'bg-gray-100 text-purple-900 font-semibold ' : 'text-gray-700',
                                            'block px-4 py-2 text-sm hover:bg-orange-50'
                                        )}
                                    >
                                        Notifications
                                    </a>
                                )}
                            </Menu.Item> */}

                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="/profile/password"
                                        className={classNames(
                                            currentPage === 'password' ? 'bg-gray-100 text-purple-900 font-semibold ' : 'text-gray-700',
                                            'block px-4 py-2 text-sm hover:bg-orange-50'
                                        )}
                                        id="menu-link-update-password"
                                    >
                                        Update Password
                                    </a>
                                )}
                            </Menu.Item>

                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href="/support"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm hover:bg-orange-50'
                                        )}
                                        id="menu-link-support"
                                    >
                                        Support
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="py-0">
                            <form method="POST" action="#" onSubmit={handleSubmit}>
                                <Menu.Item>
                                    <button role="button" 
                                        type="submit"
                                        className="block w-full flex gap-x-1 items-center px-4 py-2 text-left text-sm font-bold hover:bg-red-100 hover:text-red-900"
                                        id="menu-btn-sign-out"
                                    >
                                        <PowerIcon aria-hidden="true" />
                                        Sign Out
                                    </button>
                                </Menu.Item>
                            </form>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </>

    )
}
