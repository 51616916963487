import React from "react";
import EventsIcon from "../icons/EventsIcon";
import { Utils } from "../../utils";
import { useNavigate } from "react-router-dom";

const EventListItem = ({ event, loggedInUser, isSearchResult }) => {
    const navigate = useNavigate();

    return (
        <div
            
            onClick={(e) => navigate(`/events/${event._id}`)}
            className={`relative cursor-pointer flex flex-col md:flex-row justify-between  border-gray-200 p-3  ${(event.user._id === (loggedInUser?._id ?? "")) ? 'last:rounded-b-md' : ''} ${isSearchResult ? "bg-white" : "even:bg-white odd:bg-slate-100 hover:bg-purple-100"} `}
        >
            <div className="flex xflex-col md:flex-row items-start w-full gap-y-2 ">
                <div className="w-[100px] h-[78px] bg-purple-900x flex justify-center items-start text-purple-300">
                    <div className="w-[100px] h-full rounded-sm bg-no-repeat bg-center bg-cover" style={{ backgroundImage: `url(${event.media})` }} >
                    </div>
                </div>
                <div className="flex-grow flex flex-col justify-start pl-2 gap-y-0 items-start self-stretch">
                    <div className="flex flex-row items-start justify-between self-stretch gap-x-1">
                        <span className="text-sm font-semibold font-medium hover:underline cursor-pointer line-clamp-1">
                            {event.title}
                        </span>

                        <div className="flex flex-col flex-start w-fit items-start self-stretch">
                            {event.status === "ongoing" &&
                                <span className="w-fit whitespace-nowrap bg-green-200 text-green-800 px-2 py-1 inline-flex text-xxs font-semibold rounded-lg capitalize">
                                    on going
                                </span>
                            }
                            {event.status === "pending" &&
                                <span className="w-fit whitespace-nowrap bg-purple-200 text-purple-800 px-2 py-1 inline-flex text-xxs rounded-lg capitalize">
                                    up coming
                                </span>
                            }
                            {event.status === "done" &&
                                <span className="w-fit whitespace-nowrap bg-red-200 text-yellow-800 px-2 py-1 inline-flex text-xxs font-semibold rounded-lg capitalize">
                                    ended
                                </span>
                            }
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-start  self-stretch">
                        <div className="flex flex-col md:flex-row items-start md:items-center text-xs flex-grow gap-y-1 " >
                            <div className="flex flex-row items-center text-xs gap-x-1  capitalize mr-4">
                                <span>{(event?.event_type ?? "").replace("_", " ")}, </span>
                                <span>{event.location_type}</span>
                            </div>
                            <div className="flex flex-row items-center">
                                <div className="flex flex-row items-end">
                                    <span className="text-gray-400 mr-1">
                                        <EventsIcon />
                                    </span>
                                    <span className="inline-block">
                                        Starts
                                    </span>
                                </div>
                                <span className="text-purple-900 ml-1">
                                    {`${Utils.formatDate(event.start_date, "month date, year hrs:mins")} ${event.timezone}`}
                                </span>
                            </div>
                        </div>


                        {/* <div className="flex text-sm">
                            <div>
                                Latest Post:
                            </div>
                            <div className="truncate max-w-[200px] text-purple-900 ml-1">
                                <a className="hover:underline cursor-pointer"
                                    href={`/community/${community._id}/forum/${forum._id}/post/${forum.latest_post.post_id}`} >
                                    {forum.latest_post?.title}
                                </a>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="text-xs capitalize font-semibold">
                                <a className="hover:underline cursor-pointer">
                                    {forum.latest_post ? forum.latest_post.user.username + ", " : ""}
                                </a>
                            </span>
                            <span className="text-sm ml-2">
                                <ReactTimeAgo date={forum.latest_post.date_of_post && forum.latest_post.date_of_post.length > 0 ? forum.latest_post.date_of_post : (new Date()).toDateString()} locale="en-US" />
                            </span>
                        </div> */}
                    </div>
                    <div className="flex flex-row items-center justify-end  pt-1 ">
                        <span className="text-xs inline-block">
                            Hosted By:
                        </span>
                        <span className="text-purple-900 ml-1 text-xs">
                            {(event?.host_name?.length ?? 0) > 0 ? event?.host_name : event?.user?.username}
                        </span>
                        {/* <span className="text-xs">
                            {(event?.location_type ?? "").length > 0 ? `, ${(event?.location_type ?? "")}` : ""}
                        </span> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EventListItem;