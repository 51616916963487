

import React from "react";

const ClockIcon = ({ fillColor, className, strokeColor }) => {
    const fill = (fillColor) ? fillColor : "none";
    const stroke = (strokeColor) ? strokeColor : "currentColor";
    let classes = `w-4 h-4`;
    if (className && className.indexOf('w-') >= 0) {
        classes = ``;
    }
    classes += ` ${className}`;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill={fill} 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke={stroke} 
            className={classes}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
    );
}

export default ClockIcon;
